@import "../../../../../../../www/css/creator/style_mixins";

.plus-shop__category-headers {
  display: flex;
  flex-wrap: nowrap;
  padding: ptr(20) ptr(32);
  overflow: scroll;
  gap: ptr(8);

  @include hide-scrollbar;
}

.plus-shop__category-header {
  flex-shrink: 0;
  padding: 7px 16px;
  border: 1px solid $gray-20;
  border-radius: 999px;
  background: $white;
  color: $gray-80;
  cursor: pointer;

  &:visited,
  &:link {
    color: $gray-80;
  }

  &[data-selected="true"] {
    border-color: $gray-80;
    color: $blue;
  }

  .mshop-comp-dropdown {
    display: none;
  }
}
